import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./home.scss";
import DashboardImage from "../../assets/images/dashboard.webp";
import DashboardImage1 from "../../assets/images/laptopDashboard.png";
import DashboardImage2 from "../../assets/images/dashboardwallpaper.png";
import DashboardImage3 from "../../assets/images/dashboardwallpaper1.png";
import Team1 from "../../assets/icons/logo2.jpg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WorkFlow from "../../assets/icons/workflow.png";
import Automation from "../../assets/icons/automation.png";
import Calender from "../../assets/icons/calender.png";
import Reporting from "../../assets/icons/reporting.png";
import Timeline from "../../assets/icons/timeline.png";
import Workload from "../../assets/icons/workload.png";
import Section2Image from "../../assets/images/section2image.png";
import Star from "../../assets/icons/star1.png";
import DottedShape from "../../assets/icons/shape-005.png";
import Goals from "../../assets/images/goals.png";
import Transaction from "../../assets/icons/transactions.jpg";
import Transaction2 from "../../assets/icons/transaction.png";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState, useRef } from "react";
import Person1 from "../../assets/images/person4.webp";
import Person2 from "../../assets/images/person2.jpg";
import Person3 from "../../assets/images/person3.png";
import Person4 from "../../assets/images/avatar.webp";
import heroImg from "../../assets/images/heroImg.png";
import CountUp from "react-countup";
import APS from "../../assets/images/aps.jpg";
import MoneyGram from "../../assets/images/moneygram.png";
import UBA from "../../assets/images/uba.gif";
import AtlanticExchange from "../../assets/images/atlantic-exchange.png";
import JMMB from "../../assets/images/jmmb-money.png";
import RealTransfer from "../../assets/images/real-transfer.png";
import Nav from "../../components/nav/Nav";
import Chat from "../../assets/icons/icons8-chat-48.png";
import Chat1 from "../../assets/icons/icons8-communication-48.png";
import ReducedCost from "../../assets/images/reduced-cost.avif";
import ReducedCost1 from "../../assets/images/reduced-cost1.avif";
import Customer1 from "../../assets/images/customer-experience1.avif";
import Efficiency from "../../assets/images/efficiency.avif";
import Legal from "../../assets/images/legal.avif";
import Legal1 from "../../assets/images/legal2.avif";
import TopUp from "../../assets/icons/top-up.png";
import shape1 from "../../assets/icons/shape-008.png";
import shape2 from "../../assets/icons/shape-006.png";
import shape3 from "../../assets/icons/shape-005.png";
import shape4 from "../../assets/icons/shape-009.png";
import shape5 from "../../assets/icons/shape-007.png";
import { Link } from "react-scroll";
import Card from "../../components/card/Card";
import Hero from "../../components/hero/Hero";
import emailjs from "@emailjs/browser";

const Home = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      emailjs
        .sendForm(
          "service_4na0e9k",
          "template_nnjehdy",
          form.current,
          "mknT0HQOxDZMZe2-e"
        )
        .then(
          (result) => {
            setMessage("Thank you for your message!");
            setPhone("");
            setName("");
            setEmail("");
            setComment("");
            if (result) {
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  setTimeout(() => {
    setMessage("");
  }, 10000);

  function validate() {
    const errors = {};
    if (!name.trim()) {
      console.log(name);
      errors.name = "Name is required";
    } else if (/\d/.test(name)) {
      errors.name = "Name cannot contain a number";
    }

    if (!phone.trim()) {
      errors.phone = "Required";
    } else if (phone.length < 7) {
      errors.phone = "Must be greater or equal to 7 digits.";
    }

    if (!email.trim()) {
      errors.email = "Required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }

    if (!comment.trim()) {
      errors.comment = "Required";
    }
    setErrors(errors);

    return Object.keys(errors).length === 0;
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [isParagraph1Expanded, setIsParagraph1Expanded] = useState(false);
  const [isParagraph2Expanded, setIsParagraph2Expanded] = useState(false);
  const [isParagraph3Expanded, setIsParagraph3Expanded] = useState(false);
  const [isParagraph4Expanded, setIsParagraph4Expanded] = useState(false);
  const [isParagraph5Expanded, setIsParagraph5Expanded] = useState(false);
  const [isParagraph6Expanded, setIsParagraph6Expanded] = useState(false);
  const [isParagraph7Expanded, setIsParagraph7Expanded] = useState(false);
  const [isParagraph8Expanded, setIsParagraph8Expanded] = useState(false);
  const [isParagraph9Expanded, setIsParagraph9Expanded] = useState(false);

  const handleParagraph1 = () => {
    setIsParagraph1Expanded(!isParagraph1Expanded);
  };
  const handleParagraph2 = () => {
    setIsParagraph2Expanded(!isParagraph2Expanded);
  };
  const handleParagraph3 = () => {
    setIsParagraph3Expanded(!isParagraph3Expanded);
  };
  const handleParagraph4 = () => {
    setIsParagraph4Expanded(!isParagraph4Expanded);
  };
  const handleParagraph5 = () => {
    setIsParagraph5Expanded(!isParagraph5Expanded);
  };
  const handleParagraph6 = () => {
    setIsParagraph6Expanded(!isParagraph6Expanded);
  };
  const handleParagraph7 = () => {
    setIsParagraph7Expanded(!isParagraph7Expanded);
  };
  const handleParagraph8 = () => {
    setIsParagraph8Expanded(!isParagraph8Expanded);
  };
  const handleParagraph9 = () => {
    setIsParagraph9Expanded(!isParagraph9Expanded);
  };

  return (
    <div>
      {/* <Navbar /> */}
      {/* <Navbar1 /> */}
      <Nav />
      {/* <div id="hero" className="home">
        <img data-aos="fade-right" className="shape1" src={shape1} alt="" />
        <img data-aos="fade-left" className="shape2" src={shape2} alt="" />
        <img className="shape3" src={shape3} alt="" />
        <img className="shape4" src={shape4} alt="" />
        <img className="shape5" src={shape5} alt="" />
        
        <section>
          <div className="leftSection">
            <div className="p1">All in one remittance system</div>
            <h1>Empowering Remittance</h1>
            <p>
              Everything you need to start, <br />
              run and expand your global remittance operation.
            </p>
            <div className="link">
              <Link
                className="demo"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
              >
                Request A Demo
              </Link>
            </div>
          </div>
          <div className="rightSection">
            <img src={heroImg} alt="hero image" />
          </div>
          <div className="livechat">
            <img src={Chat1} alt="" />
          </div>
        </section>
      </div> */}
      <Hero />
      {/* <HeroSection /> */}

      <div className="middle">
        {/* <div className="image">
          <img className="dashboard" src={DashboardImage2} alt="" />
        </div> */}
        <div className="transactions">
          <div className="transaction">
            <PaidOutlinedIcon className="trans-icon" />
            <CountUp start={90601000} end={99999999} duration={10000000} />
            <p>Transactions reliably made</p>
          </div>
          <div className="transaction">
            <AddBusinessOutlinedIcon className="trans-icon" />
            <CountUp
              start={31710813000}
              end={31719999999}
              prefix={"$"}
              duration={1000000}
            />
            <p>Value entrusted by the market</p>
          </div>
          <div className="transaction">
            <PublicOutlinedIcon className="trans-icon" />
            <span>179</span>
            <p>Countries we are tried and tested in</p>
          </div>
        </div>
        {/* <p className="p2">Clients We’ve Empowered</p>
                <div className="teams">
                    <img src={APS} alt="" />
                    <img src={UBA} alt="" />
                    <img src={JMMB} alt="" />
                    <img src={MoneyGram} alt="" />
                    <img src={RealTransfer} alt="" />
                    <img src={AtlanticExchange} alt="" />
                </div> */}
      </div>
      <div id="essential" className="essential">
        <h1>Features Highlight</h1>
        <div className="essentials">
          <Card
            img="https://www.remitone.com/wp-content/uploads/2016/01/PayBills.svg"
            heading="Top-up & Pay Bills"
            sub_heading="Provide a secure virtual wallet to your customers"
            content="E-Wallet, a valuable tool for your customers to eliminate the
                risk of carrying cash. It helps those without local bank
                accounts and allows them to keep money safe and pay bills more
                easily as well as budget more effectively. Plus, they only have
                one company to deal with - you!"
          />
          <Card
            img="https://www.remitone.com/wp-content/uploads/2016/01/eWallet-1.svg"
            heading="E-Wallet"
            sub_heading="Provide a secure virtual wallet to your customers"
            content="E-Wallet, a valuable tool for your customers to eliminate the risk
            of carrying cash. It helps those without local bank accounts and
            allows them to keep money safe and pay bills more easily as well
            as budget more effectively. Plus, they only have one company to
            deal with - you!"
          />
          <Card
            img="https://www.remitone.com/wp-content/uploads/2016/01/LoyaltyPromotion-1.svg"
            heading="ID Verification"
            sub_heading="Attract and retain the customers you want"
            content="Reward loyal customers and run promotional campaigns to attract
            new ones. Configure sophisticated rules for promotional discounts
            based on time periods, remitter activity and more. Strengthen the
            loyalty of existing customers, encouraging them to do more with
            you and build on new customer relationships."
          />
          <Card
            img="https://www.remitone.com/wp-content/uploads/2016/01/IDVerification.svg"
            heading="Promotions & Loyalty"
            sub_heading="Attract and retain the customers you want"
            content="Reward loyal customers and run promotional campaigns to attract
            new ones. Configure sophisticated rules for promotional discounts
            based on time periods, remitter activity and more. Strengthen the
            loyalty of existing customers, encouraging them to do more with
            you and build on new customer relationships."
          />

          <Card
            img="https://www.remitone.com/wp-content/uploads/2016/01/Intellisense.svg"
            heading="Intelligence"
            sub_heading="Attract and retain the customers you want"
            content="Reward loyal customers and run promotional campaigns to attract
            new ones. Configure sophisticated rules for promotional discounts
            based on time periods, remitter activity and more. Strengthen the
            loyalty of existing customers, encouraging them to do more with
            you and build on new customer relationships."
          />

          <Card
            img="https://www.remitone.com/wp-content/uploads/2016/01/SeamlessUpdate.svg"
            heading="Seamless Updates"
            sub_heading="Attract and retain the customers you want"
            content="Reward loyal customers and run promotional campaigns to attract
            new ones. Configure sophisticated rules for promotional discounts
            based on time periods, remitter activity and more. Strengthen the
            loyalty of existing customers, encouraging them to do more with
            you and build on new customer relationships."
          />
          {/*
            <div className="feature">
                <div className="img_wrapper">
                <img
                    className="imgg"
                    src="https://www.remitone.com/wp-content/uploads/2016/01/PayBills.svg"
                    alt=""
                    width="315px"
                    hieght="184px"
                />
                </div>
                <div className="content">
                <h2>Top-up & Pay Bills</h2>
                <h3>Provide a secure virtual wallet to your customers</h3>
                <p>
                    E-Wallet, a valuable tool for your customers to eliminate the
                    risk of carrying cash. It helps those without local bank
                    accounts and allows them to keep money safe and pay bills more
                    easily as well as budget more effectively. Plus, they only have
                    one company to deal with - you!
                </p>
                </div>
            </div> 
            <div className="feature">
                <h1>E-Wallet</h1>
                <img
                src="https://www.remitone.com/wp-content/uploads/2016/01/eWallet-1.svg"
                alt=""
                width="315px"
                hieght="184px"
                />
                <hr />
                <h3>Provide a secure virtual wallet to your customers</h3>
                <p>
                E-Wallet, a valuable tool for your customers to eliminate the risk
                of carrying cash. It helps those without local bank accounts and
                allows them to keep money safe and pay bills more easily as well
                as budget more effectively. Plus, they only have one company to
                deal with - you!
                </p>
            </div>
            <div className="feature">
            <h1>ID Verification</h1>
            <img
              src="https://www.remitone.com/wp-content/uploads/2016/01/LoyaltyPromotion-1.svg"
              alt=""
              width="315px"
              hieght="184px"
            />
            <hr />
            <h3>Attract and retain the customers you want</h3>
            <p>
              Reward loyal customers and run promotional campaigns to attract
              new ones. Configure sophisticated rules for promotional discounts
              based on time periods, remitter activity and more. Strengthen the
              loyalty of existing customers, encouraging them to do more with
              you and build on new customer relationships.
            </p>
          </div>
          <div className="feature">
            <h1>Promotions & Loyalty</h1>
            <img
              src="https://www.remitone.com/wp-content/uploads/2016/01/IDVerification.svg"
              alt=""
              width="315px"
              hieght="184px"
            />
            <hr />
            <h3>Attract and retain the customers you want</h3>
            <p>
              Reward loyal customers and run promotional campaigns to attract
              new ones. Configure sophisticated rules for promotional discounts
              based on time periods, remitter activity and more. Strengthen the
              loyalty of existing customers, encouraging them to do more with
              you and build on new customer relationships.
            </p>
          </div>
          <div className="feature">
            <h1>Intelligence</h1>
            <img
              src="https://www.remitone.com/wp-content/uploads/2016/01/Intellisense.svg"
              alt=""
              width="315px"
              hieght="184px"
            />
            <hr />
            <h3>Attract and retain the customers you want</h3>
            <p>
              Reward loyal customers and run promotional campaigns to attract
              new ones. Configure sophisticated rules for promotional discounts
              based on time periods, remitter activity and more. Strengthen the
              loyalty of existing customers, encouraging them to do more with
              you and build on new customer relationships.
            </p>
          </div> 
          <div className="feature">
            <h1>Seamless Updates</h1>
            <img
              src="https://www.remitone.com/wp-content/uploads/2016/01/SeamlessUpdate.svg"
              alt=""
              width="315px"
              hieght="184px"
            />
            <hr />
            <h3>Attract and retain the customers you want</h3>
            <p>
              Reward loyal customers and run promotional campaigns to attract
              new ones. Configure sophisticated rules for promotional discounts
              based on time periods, remitter activity and more. Strengthen the
              loyalty of existing customers, encouraging them to do more with
              you and build on new customer relationships.
            </p>
          </div> */}
        </div>

        {/* <h2>Essentials <span style={{ color: "skyblue" }}>features</span> for driving your tasks</h2>
                <div className="essentials">
                    <div className="workflow">
                        <img src={WorkFlow} alt="" />
                        <h3>Workflow</h3>
                        <p>Quis dapibus volutpat condimentum quam sed non elit sed magna lectus dui interdum facilisi justo</p>
                    </div>
                    <div className="timeline">
                        <img src={Timeline} alt="" />
                        <h3>Timeline</h3>
                        <p>Quis dapibus volutpat condimentum quam sed non elit sed magna lectus dui interdum facilisi justo</p>
                    </div>
                    <div className="automation">
                        <img src={Automation} alt="" />
                        <h3>Automation</h3>
                        <p>Quis dapibus volutpat condimentum quam sed non elit sed magna lectus dui interdum facilisi justo</p>
                    </div>
                </div>
                <div className="essentials">
                    <div className="reporting">
                        <img src={Reporting} alt="" />
                        <h3>Reporting</h3>
                        <p>Quis dapibus volutpat condimentum quam sed non elit sed magna lectus dui interdum facilisi justo</p>
                    </div>
                    <div className="workload">
                        <img src={Workload} alt="" />
                        <h3>Workload</h3>
                        <p>Quis dapibus volutpat condimentum quam sed non elit sed magna lectus dui interdum facilisi justo</p>
                    </div>
                    <div className="calender">
                        <img src={Calender} alt="" />
                        <h3>Calender</h3>
                        <p>Quis dapibus volutpat condimentum quam sed non elit sed magna lectus dui interdum facilisi justo</p>
                    </div>
                </div> */}
      </div>
      <h2
        style={{
          fontSize: "2.8rem",
          textAlign: "center",
        }}
      >
        Benefits
      </h2>
      <section id="aboutUs" className="section2">
        <img
          className="img-benefit"
          data-aos="fade-right"
          src={Efficiency}
          alt=""
        />
        <aside>
          <img src={Star} alt="" />
          <h3>
            Increased <span style={{ color: "lightblue" }}>effieciency</span>
          </h3>
          <p>
            Quis est dapibus volutpat condimentum quam sed non elit sed. Magna
            lectus nulla eget vitae. Dui interdum in facilisi justo, consequat
            amet nunc. Eu aliquet quam.
          </p>
          <a href="">Get Started</a>
        </aside>
      </section>
      <section className="section2 goals">
        <aside>
          <h3>
            Reduced <span style={{ color: "lightcoral" }}>cost</span>
          </h3>
          <p>
            Quis est dapibus volutpat condimentum quam sed non elit sed. Magna
            lectus nulla eget vitae. Dui interdum in facilisi justo, consequat
            amet nunc. Eu aliquet quam.
          </p>
          <a href="">Get Started</a>
        </aside>
        <img
          className="img-benefit"
          data-aos="fade-left"
          src={ReducedCost1}
          alt=""
        />
      </section>
      <section className="section2 goals">
        <img
          className="img-benefit"
          data-aos="fade-right"
          src={Customer1}
          alt=""
        />
        <aside>
          <h3>
            Improved customer{" "}
            <span style={{ color: "lightcoral" }}>experience</span>
          </h3>
          <p>
            Quis est dapibus volutpat condimentum quam sed non elit sed. Magna
            lectus nulla eget vitae. Dui interdum in facilisi justo, consequat
            amet nunc. Eu aliquet quam.
          </p>
          <a href="">Get Started</a>
        </aside>
      </section>
      <section className="section2 goals">
        <aside>
          <h3>
            Fully <span style={{ color: "coral" }}>compliant</span>
          </h3>
          <p>
            Quis est dapibus volutpat condimentum quam sed non elit sed. Magna
            lectus nulla eget vitae. Dui interdum in facilisi justo, consequat
            amet nunc. Eu aliquet quam.
          </p>
          <a href="">Get Started</a>
        </aside>
        <img className="img-benefit" data-aos="fade-left" src={Legal1} alt="" />
      </section>
      <section id="pricing" className="businesplan">
        <h1>
          Choose a plan to <span style={{ color: "coral" }}>grow</span> your
          business
        </h1>
        <div className="plans-container">
          <div data-aos="fade-right" className="plans">
            {/* <div className="business plan">
                                <h2>Free</h2>
                                <p>Nemo enim ipsam voluptatem voluptas consequuntur magni dolores</p>
                                <span>$0</span>
                                <ul>
                                    <li>10 Websites</li>
                                    <li>10,000 Visits Monthly</li>
                                    <li>Dedicated Support</li>
                                    <li>Unlimited Databases</li>
                                    <li>4x More Speed</li>
                                </ul>
                                <a href="">Choose This Plan</a>
                            </div> */}
            <div className="business plan">
              <h2>Free</h2>
              <p>
                For new and small businesses issuing 50 transactions or less a
                month
              </p>
              <span>$0</span>
              <ul>
                <li> Admin only</li>
              </ul>
              <a href="">Choose This Plan</a>
            </div>
            {/* <div className="business plan">
                                <h2>Basic</h2>
                                <p>Nemo enim ipsam voluptatem voluptas consequuntur magni dolores</p>
                                <span>$199</span>
                                <ul>
                                    <li>10 Websites</li>
                                    <li>10,000 Visits Monthly</li>
                                    <li>Dedicated Support</li>
                                    <li>Unlimited Databases</li>
                                    <li>4x More Speed</li>
                                </ul>
                                <a href="">Choose This Plan</a>
                            </div> */}
            <div className="business plan">
              <h2>Standard</h2>
              <p>Our most popular entry point</p>
              <span>$199</span>
              <ul>
                <li> Unlimited transactions</li>
                <li> Client web app</li>
                <li> Easily ugradable to pro version</li>
                <li> Third party/vendor imtegrations allowed</li>
              </ul>
              <a href="">Choose This Plan</a>
            </div>
            <div className="business plan">
              <h2>Pro</h2>
              <p>Everythng in standard plus mobile app for clients</p>
              <span>$399</span>
              <ul>
                <li> Unlimited transactions</li>
                <li> Client we app</li>
                <li> Client mobile app</li>
                {/* <li> Easily upgradable to pro version</li> */}
                <li> Third party/vendor imtegrations allowed</li>
                <li> SMS/email notifications</li>
                <li> eKYC, reconciliation</li>
                <li> Digital on boarding</li>
              </ul>
              <a href="">Choose This Plan</a>
            </div>
            {/* <div className="business plan">
                                <h2>Enterprise / White Label Custom</h2>
                                <p>Nemo enim ipsam voluptatem voluptas consequuntur magni dolores</p>
                                <span>$0</span>
                                <ul>
                                    <li>10 Websites</li>
                                    <li>10,000 Visits Monthly</li>
                                    <li>Dedicated Support</li>
                                    <li>Unlimited Databases</li>
                                    <li>4x More Speed</li>
                                </ul>
                                <a href="">Choose This Plan</a>
                            </div> */}
          </div>
          <div data-aos="fade-left" className="business1 plan">
            <h2>Enterprise / White Label Custom</h2>
            <p>Contact us lets have a chat.</p>
            <Link
              className="a"
              to="contact-us"
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
            >
              Talk To Us
            </Link>
          </div>
        </div>
      </section>
      <section id="faq" className="quest">
        <h1>
          Got <span style={{ color: "darkblue" }}>questions</span>? <br /> we
          are here to help
        </h1>
        <div className="questions">
          <aside>
            <div className="question">
              <h4>
                <p className={isParagraph1Expanded ? "hOpen1" : "hClose1"}>
                  What is I-Remit Software?
                </p>
                {isParagraph1Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph1}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph1}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph1Expanded ? "paraOpen1" : "paraClose1"}>
                I-Remit is a software as a service platform that enables
                individuals and businesses to send and receive money
                electronically across borders. It provides a digital platform
                for remittance service providers to manage their operations and
                enables customers to initiate, track and manage their
                transactions.
              </p>
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph2Expanded ? "hOpen2" : "hClose2"}>
                  How does I-Remit software work?
                </p>
                {isParagraph2Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph2}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph2}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph2Expanded ? "paraOpen2" : "paraClose2"}>
                I-Remit works by providing a digital platform that enables
                remittance service providers to manage their operations more
                efficiently. The platform allows customers to initiate, track
                and manage their transactions, and provides remittance service
                providers with tools to manage their compliance, risk and fraud
                management.
              </p>

              {/* <h4>
                                    <a href="">Why should we woek with you when we already have a procurement department?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph3Expanded ? "hOpen3" : "hClose3"}>
                  What are the benefits of using I-Remit software?
                </p>
                {isParagraph3Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph3}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph3}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph3Expanded ? "paraOpen3" : "paraClose3"}>
                The benefits of using I-Remit software include faster and more
                convenient money transfers, lower costs compared to traditional
                money transfer methods, increased transparency and security, and
                more efficient compliance and fraud management.
              </p>
              {/* <h4>
                                    <a href="">How is spendflo better than other solutions in the market?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph8Expanded ? "hOpen8" : "hClose8"}>
                  Can I cancel a money transfer using I-Remit software?
                </p>
                {isParagraph8Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph8}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph8}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph8Expanded ? "paraOpen8" : "paraClose8"}>
                The ability to cancel a money transfer using the I-Remit
                software depends on the remittance service provider that you
                use. Some providers may allow you to cancel a transaction if it
                has not yet been processed, while others may not allow
                cancellations. You should check with your remittance service
                provider to find out their policy on cancellations.
              </p>
              {/* <h4>
                                    <a href="">Do you also double as IRemit management platform?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph9Expanded ? "hOpen9" : "hClose9"}>
                  What happens if there is an issue with my money transfer using
                  the I-Remit software?
                </p>
                {isParagraph9Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph9}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph9}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph9Expanded ? "paraOpen9" : "paraClose9"}>
                If there is an issue with your money transfer using the I-Remit
                software, you should contact your remittance service provider
                for assistance. The platform provides customer support to help
                resolve any issues that may arise during the transfer process.
              </p>
              {/* <h4>
                                    <a href="">Do you also double as IRemit management platform?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
          </aside>
          <aside className="aside2">
            <div className="question">
              <h4>
                <p className={isParagraph4Expanded ? "hOpen4" : "hClose4"}>
                  Who can use I-Remit software?
                </p>
                {isParagraph4Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph4}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph4}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph4Expanded ? "paraOpen4" : "paraClose4"}>
                I-Remit software can be used by both individuals and businesses
                who need to send or receive money across borders. Remittance
                service providers can also use the platform to manage their
                operations and provide services to their customers.
              </p>
              {/* <h4>
                                    <a href="">What industries and businesses does spindle benefit the most?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph5Expanded ? "hOpen5" : "hClose5"}>
                  Is I-Remit software safe to use?
                </p>
                {isParagraph5Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph5}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph5}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph5Expanded ? "paraOpen5" : "paraClose5"}>
                Yes, I-Remit software is safe to use. It uses advanced security
                measures to protect the confidentiality and integrity of
                customer data and transactions. The platform also complies with
                regulatory requirements to ensure that it operates within the
                legal framework of each country where it is available.
              </p>
              {/* <h4>
                                    <a href="">How can we trust you with our strategic vendor relationships that we have build over time?</a>
                                    <AddIcon />    
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph6Expanded ? "hOpen6" : "hClose6"}>
                  How long does it take to transfer money using I-Remit
                  software?
                </p>
                {isParagraph6Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph6}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph6}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph6Expanded ? "paraOpen6" : "paraClose6"}>
                The time it takes to transfer money using I-Remit software
                depends on the remittance service provider that you use and the
                destination country. Typically, it takes between a few minutes
                to a few days for the money to be transferred, depending on the
                payment method used and any regulatory requirements that need to
                be met.
              </p>
              {/* <h4>
                                    <a href="">Do you also double as IRemit management platform?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
            <div className="question">
              <h4>
                <p className={isParagraph7Expanded ? "hOpen7" : "hClose7"}>
                  Can I track my money transfer using I-Remit software?
                </p>
                {isParagraph7Expanded ? (
                  <ExpandLessIcon
                    onClick={handleParagraph7}
                    className="expnicon"
                  />
                ) : (
                  <AddCircleOutlineIcon
                    onClick={handleParagraph7}
                    className="expnicon"
                  />
                )}
              </h4>
              <p className={isParagraph7Expanded ? "paraOpen7" : "paraClose7"}>
                Yes, you can track your money transfer using I-Remit software.
                The platform provides real-time updates on the status of your
                transaction, including when it has been initiated, when it has
                been processed, and when the money has been received by the
                recipient.
              </p>
              {/* <h4>
                                    <a href="">Do you also double as IRemit management platform?</a>
                                    <AddIcon />
                                </h4>
                                <p>
                                    Quis est dapibus volutpat condimentum quam sed non elit sed. Magna lectus nulla eget vitae. Dui interdum in facilisi justo, consequat amet nunc. Eu aliquet quam.
                                </p> */}
            </div>
          </aside>
        </div>
      </section>
      <div className="meetings">
        <div className="meeting">
          <h1>Start spending less time on your meetings</h1>
          {/* <a className="demo" style={{color: "white"}} href="">Request A Demo</a> */}
          <Link
            className="demo"
            to="contact-us"
            spy={true}
            smooth={true}
            offset={-100}
            duration={1000}
          >
            Request A Demo
          </Link>
        </div>
      </div>
      <div className="testimonials">
        <h2>What Others Say</h2>
        <div className="people">
          <div className="person-content-wrapper">
            <div className="person">
              <img src={Person1} alt="" />
              <h3>
                Person One, CEO <br /> United Bank of Africa
              </h3>
              <i>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
                quia aliquam eos dicta voluptatibus obcaecati illum quos quas.
                Voluptates autem eos at nisi cum quae voluptate sapiente, fugit
                consectetur laboriosam!
              </i>
            </div>
          </div>
          <div className="person-content-wrapper">
            <div className="person">
              <img src={Person4} alt="" />
              <h3>
                Person Two, Former CEO <br /> Moneygram
              </h3>
              <i>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
                quia aliquam eos dicta voluptatibus obcaecati illum quos quas.
                Voluptates autem eos at nisi cum quae voluptate sapiente, fugit
                consectetur laboriosam!
              </i>
            </div>
          </div>
          <div className="person-content-wrapper">
            <div className="person">
              <img src={Person3} alt="" />
              <h3>
                Person Three, CEO <br /> ZigTech
              </h3>
              <i>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
                quia aliquam eos dicta voluptatibus obcaecati illum quos quas.
                Voluptates autem eos at nisi cum quae voluptate sapiente, fugit
                consectetur laboriosam!
              </i>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-us" className="contact-form">
        <h2>Contact Us</h2>
        <form action="" ref={form} onSubmit={handleSubmit}>
          <div className={`message ${message ? "showMsg" : "hideMsg"}`}>
            {message}
          </div>
          {/* <div>
            <div className="nname">
              <input
                value={fName}
                type="text"
                name="fName"
                id="fName"
                placeholder="Firstname"
                onChange={(e) => setFname(e.target.value)}
              />
              <input
                value={lName}
                type="text"
                name="lName"
                id="lName"
                placeholder="Lastname"
                onChange={(e) => setLname(e.target.value)}
              />
            </div>
            <span className="error">{errors.name}</span>
          </div> */}
          <div className="form-control">
            <input
              value={name}
              type="name"
              name="name"
              id="name"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
            <span className="error">{errors.name}</span>
          </div>
          <div className="form-control">
            <input
              value={phone}
              type="phone"
              name="phone"
              id="phone"
              placeholder="phone"
              onChange={(e) => setPhone(e.target.value)}
            />
            <span className="error">{errors.phone}</span>
          </div>
          <div className="form-control">
            <input
              value={email}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="error">{errors.email}</span>
          </div>
          <div className="form-control">
            <textarea
              value={comment}
              name="comment"
              id="comment"
              cols="30"
              rows="10"
              placeholder="Questions/Comments"
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <span className="error">{errors.comment}</span>
          </div>
          <div className="send">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
