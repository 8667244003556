import './footer.scss';
import Logo from '../../assets/icons/logo2.jpg';
import Facebook from '../../assets/icons/social media/icons8-facebook-48.png';
import Instagram from '../../assets/icons/social media/icons8-instagram-48.png';
import Whatsapp from '../../assets/icons/social media/icons8-whatsapp-48.png';
import Twitter from '../../assets/icons/social media/icons8-twitter-48.png';
import LinkedIn from '../../assets/icons/social media/icons8-linkedin-circled-48.png';

const Footer = () => {
    return ( 
        <div className="footer">
            <div className="top">
                <div className="usefullinks">
                    <img src={Logo} alt="" />
                    <p>Pipeline, Kairaba Avenue, Kanifing Municipal Council.</p>
                    <a href="mailto:iremit@example.com">iremit@example.com</a>
                    <p>(+220) 3718888</p>
                    {/* <ul>
                        <li><a href=""><img src={Logo} alt="" /></a></li>
                        <li>Pipeline, Kairaba Avenue, Kanifing Municipal Council.</li>
                        <li><a href="mailto:iremit@example.com">iremit@example.com</a></li>
                    </ul> */}
                </div>
                <div className="usefullinks">
                    <h2>Useful links</h2>
                    <ul>
                        <li><a href="">About Agency</a></li>
                        <li><a href="">About Business</a></li>
                        <li><a href="">Our Mission</a></li>
                        <li><a href="">Our History</a></li>
                    </ul>
                </div>
                <div className="usefullinks">
                    <h2>Community</h2>
                    <ul>
                        <li><a href="">Contact Us</a></li>
                        <li><a href="">Classic Fullwidth</a></li>
                        <li><a href="">Classic Boxed</a></li>
                        <li><a href="">Creative Boxed</a></li>
                    </ul>
                </div>
                <div className="usefullinks">
                    <h2>Partners</h2>
                    <ul>
                        <li><a href="">Support</a></li>
                        <li><a href="">Classic Fullwidth</a></li>
                        <li><a href="">Classic Boxed</a></li>
                        <li><a href="">Creative Boxed</a></li>
                    </ul>
                </div>
            </div>
            <hr />
            <div className="contents">
                <p>&copy; 2022 ZigTech. All Rights Reserved</p>
                <a href="">Terms of Service</a>
                <a href="">Privacy Policy</a>
                <a href="">Other Terms & Policies</a>
                <div className="media">
                    <ul>
                        <li><a href=""><img src={LinkedIn} alt="" /></a></li>
                        <li><a href=""><img src={Facebook} alt="" /></a></li>
                        <li><a href=""><img src={Twitter} alt="" /></a></li>
                        <li><a href=""><img src={Whatsapp} alt="" /></a></li>
                        <li><a href=""><img src={Instagram} alt="" /></a></li>
                    </ul>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;