import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Aos from "aos";
import illustraion from "../../assets/icons/illustration.png";
import banner1 from "../../assets/images/banner1.jpg";
import shape1 from "../../assets/icons/shape-008.png";
import shape2 from "../../assets/icons/shape-006.png";
import shape3 from "../../assets/icons/shape-005.png";
import shape4 from "../../assets/icons/shape-009.png";
import shape5 from "../../assets/icons/shape-007.png";

import banner2 from "../../assets/images/banner2.jpg";

import "./hero.scss";
import FeatureBox from "../featureBox/FeatureBox";

const Hero = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="hero">
      {/* <img data-aos="fade-right" className="shape1" src={shape1} alt="" />
      <img data-aos="fade-left" className="shape2" src={shape2} alt="" />
      <img className="shape3" src={shape3} alt="" />
      <img className="shape4" src={shape4} alt="" />
      <img className="shape5" src={shape5} alt="" /> */}

      <div className="wrapper">
        <div className="container p-12">
          <div className="left text-center md:text-left">
            <h1>Empowering Remittance</h1>
            <p className="text-center">
              Everything you need to start, <br />
              run and expand your global remittance operation.
            </p>
            <div className="link">
              <Link
                className="demo"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
              >
                Request A Demo
              </Link>
            </div>
          </div>
          <div className="right">
            <div>
              <img src={illustraion} alt="banner" />
            </div>
          </div>
        </div>
        <div className="mobileContainer">
          <div className="mobile">
            <div data-aos="fade-right" className="left">
              <h1>Empowering Remittance</h1>
              <p>
                Everything you need to start, run and expand your global
                remittance operation.
              </p>
              <div className="link">
                <Link
                  className="demo"
                  to="contact-us"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={1000}
                >
                  Request A Demo
                </Link>
              </div>
            </div>
            <div className="right" data-aos="fade-left">
              <img src={illustraion} alt="banner" />
            </div>
          </div>
        </div>
      </div>
      <FeatureBox />
    </div>
  );
};

export default Hero;
