import "./nav.scss";
import Logo from "../../assets/icons/logo2.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import ListIcon from "@mui/icons-material/List";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-scroll";

const Nav = () => {
  const nav = useRef();
  const [menu, setMenu] = useState(false);
  const showMenu = () => {
    setMenu(!menu);
  };
  const closeMenu = () => {
    setMenu(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (nav.current && window.scrollY >= "106.25") {
        nav.current.classList.add("scroll_bg");
      } else {
        nav.current.classList.remove("scroll_bg");
      }
    });
  }, []);

  return (
    <div id="home" className="navContainer">
      <div ref={nav} className="nav">
        <Link
          style={{
            cursor: "pointer",
          }}
          to="hero"
          spy={true}
          smooth={true}
          offset={-150}
          duration={1000}
          onClick={closeMenu}
        >
          <img src={Logo} alt="" />
        </Link>
        <ul className={menu ? "ul-2" : "ul"}>
          <li className="hme">
            <Link
              activeClass="active"
              to="hero"
              spy={true}
              smooth={true}
              offset={-150}
              duration={1000}
              onClick={closeMenu}
            >
              Home
            </Link>
          </li>
          {/* <li>
            <Link
              to="aboutUs"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-200}
              duration={1000}
              onClick={closeMenu}
            >
              About
            </Link>
          </li> */}
          <li>
            <Link
              to="essential"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
              onClick={closeMenu}
            >
              Features
            </Link>
          </li>
          <li>
            <Link
              to="pricing"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-180}
              duration={1000}
              onClick={closeMenu}
            >
              Pricing
            </Link>
          </li>
          <li>
            <Link
              to="faq"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-120}
              duration={1000}
              onClick={closeMenu}
            >
              FAQ
            </Link>
          </li>
          <li>
            <Link
              to="contact-us"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-80}
              duration={1000}
              onClick={closeMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>

        <div className="phone">
          {/* <PhoneIcon />
                <h3>(+220) 3718888</h3> */}
          <Link
            to="contact-us"
            spy={true}
            smooth={true}
            offset={-100}
            duration={1000}
          >
            Get Started
          </Link>
        </div>

        {menu ? (
          <CloseIcon onClick={showMenu} className="menu-icon" />
        ) : (
          <ListIcon onClick={showMenu} className="menu-icon" />
        )}
      </div>
    </div>
  );
};

export default Nav;
