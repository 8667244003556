import { Hidden } from "@mui/material";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";

function App() {
  return (
    <div className="App" style={{ overflowX: "hidden" }}>
      <Home />
    </div>
  );
}

export default App;
