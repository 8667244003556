import React from "react";
import "./card.scss";

const Card = ({ img, heading, sub_heading, content }) => {
  return (
    <div className="card">
      <div className="img_wrapper">
        <img src={img} alt="" />
      </div>
      <div className="content">
        <h2>{heading}</h2>
        <h3>{sub_heading}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Card;
