import React from "react";
import "./featureBox.scss";
import piggybank from "../../assets/icons/piggy-bank.png";
import encryption from "../../assets/icons/encryption.png";
import wallet from "../../assets/icons/wallet.png";
import security from "../../assets/icons/shield.png";
import { Link } from "react-scroll";

const FeatureBox = () => {
  return (
    <div className="feature-container">
      <div className="inner-wrapper">
        <div className="row">
          <div className="box">
            <div className="icon-wrapper">
              <img src={piggybank} alt="" />
            </div>
            <h4>Transparent Pricing</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              non aut a autem saepe accusamus quisquam, molestiae.
            </p>
            {/* <Link
              className="link"
              to="pricing"
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
            >
              Explore
            </Link> */}
          </div>
          <div className="box">
            <div className="icon-wrapper">
              <img src={encryption} alt="" />
            </div>
            <h4>Fully encrypted</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              non aut a autem saepe accusamus quisquam, molestiae.
            </p>
          </div>
          <div className="box">
            <div className="icon-wrapper">
              <img src={wallet} alt="" />
            </div>
            <h4>instant cashout</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              non aut a autem saepe accusamus quisquam, molestiae.
            </p>
          </div>
          <div className="box">
            <div className="icon-wrapper">
              <img src={security} alt="" />
            </div>
            <h4>safe and secure</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              non aut a autem saepe accusamus quisquam, molestiae.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureBox;
